/* src/HamburgerMenu.css */

.hamburger-container {
    display: flex;
    width: 40px;
    height: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    cursor: pointer;
  }
  
  .line {
    display: block;
    height: 3px;
    width: 30px;
    margin-bottom: 3px;
    margin-top: 2px;
    background: white;
    border-radius: 9px;
    opacity: 1;
    -webkit-transition: .1s ease-in-out;
    -moz-transition: .1s ease-in-out;
    -o-transition: .1s ease-in-out;
    transition: .1s ease-in-out;
  }

  .line2 {
    width: 20px;
  }

 .line3 {   
    width: 10px;
  }
  
  .active.line1 {
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  
  .active.line2 {
    display: none;
  }
  
  .active.line3 {
    margin-top: -5px;
    width: 30px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }
  